export const clickOutside = (node: HTMLElement): { destroy: () => void } => {
	if (!node) throw new Error('Invlid HTMLElement provided');

	const handleClick = (event: MouseEvent): void => {
		const target = event.target as Node | null;

		if (target && !node.contains(target) && !event.defaultPrevented) {
			const customEvent = new CustomEvent('click_outside', { detail: node });
			node.dispatchEvent(customEvent);
		}
	};

	document.addEventListener('click', handleClick, true);

	return {
		destroy() {
			document.removeEventListener('click', handleClick, true);
		}
	};
};
